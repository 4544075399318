const OPERATORS_MAPPING: Record<string, string> = {
  '=': '$eq',
  '!=': '$ne',
  '>': '$gt',
  '>=': '$gte',
  '<': '$lt',
  '<=': '$lte',
}

const NUMBERS = [
  'score',
  'speed',
  'brake_pedal_score',
  'brake_pedal',
  'steering_angle',
  'gas_pedal',
  'acceleration',
  'gear',
  'max_speed',
  'lane_count',
]

const shouldBeNumber = (type: string, value: string) =>
  NUMBERS.indexOf(type) !== -1 ? parseFloat(value) : value

const toCamelCase = (name: string) =>
  name
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('_', ''))

export const parseSearchParams = (params: string[]): any => {
  return params.reduce((acc, param) => {
    const splitParam = param.split(' ')
    acc[toCamelCase(splitParam[0]) as string] = {
      [OPERATORS_MAPPING[splitParam[1] as string]]: shouldBeNumber(
        splitParam[0],
        splitParam[2]
      ),
    }
    return acc
  }, {} as any)
}
