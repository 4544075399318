import React, { useCallback, useEffect, useRef, useState } from 'react'
import style from './style.less'
import Select from '@mui/material/Select'
import Icon from '../Icon/Icon'
import MenuItem from '@mui/material/MenuItem'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Typography, {
  TypographyTypes,
} from '@yaak/components/src/Typography/Typography'
import Chip from '@mui/material/Chip'
import { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete/Autocomplete'
import ListItemText from '@mui/material/ListItemText'
import ListItem from '@mui/material/ListItem'
import List from '@mui/material/List'
import { useClickOutside } from '../../customHooks/useClickOutside'
import Button from '../Button'
import SaveEditQueryDialog from './dialogs/SaveEditQueryDialog'
import classNames from 'classnames'
import WarningDialog from '../WarningDialog'
import DateRangePicker from 'rsuite/DateRangePicker'
import { predefinedRanges } from '../../helpers/dateRangePicker'
import endOfDay from 'date-fns/endOfDay'

const uuid = require('uuid')

const AUTOCOMPLETE_TABS = {
  NEW: 'New query',
  SAVED: 'Saved queries',
}

export type MergedListKey = 'key' | 'value' | 'group' | 'subgroup'

const GROUPS = {
  GENERAL: 'GENERAL',
  DYNAMIC_DATA: 'DYNAMIC DATA',
  MAP_BASED_DATA: 'MAP-BASED DATA',
}

const SUBGROUPS = {
  DRIVER_PERFORMANCE: 'Driver performance',
  ENVIRONMENTAL_CONDITIONS: 'Environmental conditions',
  OBJECTS_AGENTS: 'Objects & agents',
  TRAFFIC_INFRASTRUCTURE: 'Traffic infrastructure',
  ENVIRONMENTAL_TOPOLOGY: 'Environmental topology',
}

interface createOptionProps {
  value: string
  group?: string
  subgroup?: string
  key?: string
  onCopy?: (value: string) => void
  onEdit?: (value: string) => void
  onDelete?: (value: string) => void
}

interface Option {
  value: string
  name: string
  item: (props: React.HTMLAttributes<HTMLDivElement>) => any
  group?: string
  subgroup?: string
}

const createOption = ({
  value,
  group,
  subgroup,
  key,
  onCopy,
  onEdit,
  onDelete,
}: createOptionProps): Option => {
  return {
    value: key ? `${key}_${value}` : `${uuid.v4()}_${value}`,
    name: value,
    item: (props: React.HTMLAttributes<HTMLDivElement>) =>
      subgroup ? (
        <div key={key || `${uuid.v4()}_${value}`}>
          <div className={style.subgroup}>{subgroup}</div>
          <div
            {...props}
            className={style.option}
            key={key || `${uuid.v4()}_${value}`}
          >
            {value}
          </div>
        </div>
      ) : (
        <div
          {...props}
          className={classNames(
            style.option,
            onEdit ? style.savedQueryOption : undefined
          )}
          key={key || `${uuid.v4()}_${value}`}
        >
          {value}
          {onEdit && (
            <div className={style.optionActions}>
              <Icon
                name={'Copy'}
                className={style.icon}
                onClick={(event) => {
                  event.stopPropagation()
                  onCopy?.(value)
                }}
              />
              <Icon
                name={'Edit'}
                className={style.icon}
                onClick={(event) => {
                  event.stopPropagation()
                  onEdit?.(value)
                }}
              />
              <Icon
                name={'Delete'}
                className={style.icon}
                onClick={(event) => {
                  event.stopPropagation()
                  onDelete?.(value)
                }}
              />
            </div>
          )}
        </div>
      ),
    group,
  }
}

interface OptionWithKey {
  value: string
  key: string
}

const createOptions = (options: string[] | OptionWithKey[], group?: string) =>
  options.map((option) =>
    createOption({
      value: ((option as OptionWithKey).value as string) || (option as string),
      key: (option as OptionWithKey).key as string,
      group,
    })
  )

type VALUES_KEYS =
  | 'drive_type'
  | 'incident'
  | 'lighting'
  | 'conditions'
  | 'gear'
  | 'turn_signal'
  | 'road_type'
  | 'traffic_facilities'
  | 'traffic_junctions'
  | 'road_attributes'
  | 'road_surface'

const VALUES: Record<VALUES_KEYS, Option[]> = {
  drive_type: createOptions(['Student', 'Expert']),
  incident: createOptions([
    'Visual check',
    'Distance',
    'Handling',
    'Turn signal',
    'Signs & lights',
    'Speed',
    'Placement',
    'Right of way',
    'Instructor pedal',
  ]),
  lighting: createOptions(['Day', 'Night', 'Transition']),
  conditions: createOptions([
    'Thunderstorm',
    'Drizzle',
    'Rain',
    'Snow',
    'Mist',
    'Smoke',
    'Haze',
    'Dust',
    'Fog',
    'Sand',
    'Ash',
    'Squall',
    'Tornado',
    'Clear',
    'Clouds',
  ]),
  gear: createOptions(['Neutral', 'Reverse', 'Drive', 'Park']),
  turn_signal: createOptions(['Left', 'Right']),
  road_type: createOptions([
    'Highway',
    'Trunk',
    'Primary',
    'Secondary',
    'Tertiary',
    'Residential',
  ]),
  traffic_facilities: createOptions([
    'BUS_STOP',
    'LOWERED_KERB',
    'PEDESTRIAN_CROSSING',
    'TRAFFIC_CALMER',
    'TRAIN_CROSSING',
    'TRAM_TRACKS',
  ]),
  traffic_junctions: createOptions([
    'ENTERING_MOVING_TRAFFIC',
    'MERGE_IN_OUT_ON_HIGHWAY',
    'MULTILANE_LEFT',
    'MULTILANE_RIGHT',
    'PROTECTED_LEFT',
    'RIGHT_BEFORE_LEFT',
    'JUNCTION_RIGHT_BEFORE_LEFT',
    'RIGHT_TURN_ON_RED',
    'ROAD_NARROWS',
    'ROUNDABOUT',
    'UNPROTECTED_LEFT',
    'UNPROTECTED_RIGHT_WITH_BIKE',
  ]),
  road_attributes: createOptions([
    'GIVE_WAY',
    'HILL_DRIVE',
    'LIMITED_ACCESS_WAY',
    'LIVING_STREET',
    'LOW_SPEED_REGION',
    'ONE_WAY',
    'PRIORITY_FORWARD_BACKWARD',
    'PRIORITY_WAY',
    'RIGHT_OF_WAY',
    'TUNNEL',
  ]),
  road_surface: [
    ...createOptions(
      [
        'paved',
        'asphalt',
        'chipseal',
        'concrete',
        'concrete:lanes',
        'concrete:plates',
        'paving_stones',
        'sett',
        'unhewn_cobblestone',
        'cobblestone',
        'bricks',
        'metal',
        'wood',
        'stepping_stones',
        'rubber',
        'tiles',
      ],
      'PAVED'
    ),
    ...createOptions(
      [
        'unpaved',
        'compacted',
        'fine_gravel',
        'gravel',
        'shells',
        'rock',
        'pebblestone',
        'ground',
        'dirt',
        'earth',
        'grass',
        'grass_paver',
        'metal_grid',
        'mud',
        'sand',
        'woodchips',
        'snow',
        'ice',
        'salt',
      ],
      'UNPAVED'
    ),
    ...createOptions(
      ['clay', 'tartan', 'artificial_turf', 'acrylic', 'carpet', 'plastic'],
      'SPECIAL'
    ),
  ],
}

type OPERATORS_KEYS =
  | 'drive_type'
  | 'kit_id'
  | 'bitrate'
  | 'driver'
  | 'driver_id'
  | 'location'
  | 'incident'
  | 'score'
  | 'brake_pedal_score'
  | 'gas_pedal_score'
  | 'steering_angle_score'
  | 'lighting'
  | 'conditions'
  | 'steering_angle'
  | 'brake_pedal'
  | 'gas_pedal'
  | 'gear'
  | 'speed'
  | 'acceleration'
  | 'turn_signal'
  | 'max_speed'
  | 'traffic_facilities'
  | 'traffic_junctions'
  | 'road_attributes'
  | 'road_type'
  | 'lane_count'
  | 'road_surface'

type SUBGROUPS_OPERATORS_KEYS =
  | 'incident'
  | 'lighting'
  | 'steering_angle'
  | 'max_speed'
  | 'road_type'

const createAllOperators = () =>
  createOptions([
    { value: '=', key: uuid.v4() },
    { value: '!=', key: uuid.v4() },
    { value: '<', key: uuid.v4() },
    { value: '<=', key: uuid.v4() },
    { value: '>', key: uuid.v4() },
    { value: '>=', key: uuid.v4() },
  ])

const OPERATORS: Record<OPERATORS_KEYS, Option[]> = {
  drive_type: createOptions([
    { value: '=', key: uuid.v4() },
    { value: '!=', key: uuid.v4() },
  ]),
  kit_id: createOptions([
    { value: '=', key: uuid.v4() },
    { value: '!=', key: uuid.v4() },
  ]),
  bitrate: createAllOperators(),
  driver: createOptions([
    { value: '=', key: uuid.v4() },
    { value: '!=', key: uuid.v4() },
  ]),
  driver_id: createOptions([{ value: '=', key: uuid.v4() }]),
  location: createOptions([
    { value: '=', key: uuid.v4() },
    { value: '!=', key: uuid.v4() },
  ]),
  incident: createOptions([
    { value: '=', key: uuid.v4() },
    { value: '!=', key: uuid.v4() },
  ]),
  score: createAllOperators(),
  brake_pedal_score: createAllOperators(),
  gas_pedal_score: createAllOperators(),
  steering_angle_score: createAllOperators(),
  lighting: createOptions([
    { value: '=', key: uuid.v4() },
    { value: '!=', key: uuid.v4() },
  ]),
  conditions: createOptions([
    { value: '=', key: uuid.v4() },
    { value: '!=', key: uuid.v4() },
  ]),
  steering_angle: createAllOperators(),
  gas_pedal: createAllOperators(),
  brake_pedal: createAllOperators(),
  gear: createOptions([
    { value: '=', key: uuid.v4() },
    { value: '!=', key: uuid.v4() },
  ]),
  speed: createAllOperators(),
  acceleration: createAllOperators(),
  turn_signal: createOptions([
    { value: '=', key: uuid.v4() },
    { value: '!=', key: uuid.v4() },
  ]),
  max_speed: createAllOperators(),
  traffic_facilities: createOptions([
    { value: '=', key: uuid.v4() },
    { value: '!=', key: uuid.v4() },
  ]),
  traffic_junctions: createOptions([
    { value: '=', key: uuid.v4() },
    { value: '!=', key: uuid.v4() },
  ]),
  road_attributes: createOptions([
    { value: '=', key: uuid.v4() },
    { value: '!=', key: uuid.v4() },
  ]),
  road_type: createOptions([
    { value: '=', key: uuid.v4() },
    { value: '!=', key: uuid.v4() },
  ]),
  lane_count: createAllOperators(),
  road_surface: createOptions([
    { value: '=', key: uuid.v4() },
    { value: '!=', key: uuid.v4() },
  ]),
}

const GROUPS_MAPPING: Record<OPERATORS_KEYS, string> = {
  drive_type: GROUPS.GENERAL,
  kit_id: GROUPS.GENERAL,
  bitrate: GROUPS.GENERAL,
  driver: GROUPS.GENERAL,
  driver_id: GROUPS.GENERAL,
  location: GROUPS.GENERAL,
  incident: GROUPS.DYNAMIC_DATA,
  score: GROUPS.DYNAMIC_DATA,
  brake_pedal_score: GROUPS.DYNAMIC_DATA,
  gas_pedal_score: GROUPS.DYNAMIC_DATA,
  steering_angle_score: GROUPS.DYNAMIC_DATA,
  lighting: GROUPS.DYNAMIC_DATA,
  conditions: GROUPS.DYNAMIC_DATA,
  steering_angle: GROUPS.DYNAMIC_DATA,
  gas_pedal: GROUPS.DYNAMIC_DATA,
  brake_pedal: GROUPS.DYNAMIC_DATA,
  gear: GROUPS.DYNAMIC_DATA,
  speed: GROUPS.DYNAMIC_DATA,
  acceleration: GROUPS.DYNAMIC_DATA,
  turn_signal: GROUPS.DYNAMIC_DATA,
  max_speed: GROUPS.MAP_BASED_DATA,
  traffic_facilities: GROUPS.MAP_BASED_DATA,
  traffic_junctions: GROUPS.MAP_BASED_DATA,
  road_attributes: GROUPS.MAP_BASED_DATA,
  road_type: GROUPS.MAP_BASED_DATA,
  lane_count: GROUPS.MAP_BASED_DATA,
  road_surface: GROUPS.MAP_BASED_DATA,
}

const SUBGROUPS_MAPPING: Record<SUBGROUPS_OPERATORS_KEYS, string> = {
  incident: SUBGROUPS.DRIVER_PERFORMANCE,
  lighting: SUBGROUPS.ENVIRONMENTAL_CONDITIONS,
  steering_angle: SUBGROUPS.OBJECTS_AGENTS,
  max_speed: SUBGROUPS.TRAFFIC_INFRASTRUCTURE,
  road_type: SUBGROUPS.ENVIRONMENTAL_CONDITIONS,
}

const OPTIONS: Option[] = [
  createOption({ value: 'drive_type', group: GROUPS.GENERAL }),
  createOption({ value: 'kit_id', group: GROUPS.GENERAL }),
  createOption({ value: 'bitrate', group: GROUPS.GENERAL }),
  createOption({ value: 'driver', group: GROUPS.GENERAL }),
  createOption({ value: 'driver_id', group: GROUPS.GENERAL }),
  createOption({ value: 'location', group: GROUPS.GENERAL }),
  createOption({
    value: 'incident',
    group: GROUPS.DYNAMIC_DATA,
    subgroup: SUBGROUPS.DRIVER_PERFORMANCE,
  }),
  createOption({ value: 'score', group: GROUPS.DYNAMIC_DATA }),
  createOption({ value: 'brake_pedal_score', group: GROUPS.DYNAMIC_DATA }),
  createOption({ value: 'gas_pedal_score', group: GROUPS.DYNAMIC_DATA }),
  createOption({ value: 'steering_angle_score', group: GROUPS.DYNAMIC_DATA }),
  createOption({
    value: 'lighting',
    group: GROUPS.DYNAMIC_DATA,
    subgroup: SUBGROUPS.ENVIRONMENTAL_CONDITIONS,
  }),
  createOption({ value: 'conditions', group: GROUPS.DYNAMIC_DATA }),
  createOption({
    value: 'steering_angle',
    group: GROUPS.DYNAMIC_DATA,
    subgroup: SUBGROUPS.OBJECTS_AGENTS,
  }),
  createOption({ value: 'gas_pedal', group: GROUPS.DYNAMIC_DATA }),
  createOption({ value: 'brake_pedal', group: GROUPS.DYNAMIC_DATA }),
  createOption({ value: 'gear', group: GROUPS.DYNAMIC_DATA }),
  createOption({ value: 'speed', group: GROUPS.DYNAMIC_DATA }),
  createOption({ value: 'acceleration', group: GROUPS.DYNAMIC_DATA }),
  createOption({ value: 'turn_signal', group: GROUPS.DYNAMIC_DATA }),
  createOption({
    value: 'max_speed',
    group: GROUPS.MAP_BASED_DATA,
    subgroup: SUBGROUPS.TRAFFIC_INFRASTRUCTURE,
  }),
  createOption({ value: 'traffic_facilities', group: GROUPS.MAP_BASED_DATA }),
  createOption({ value: 'traffic_junctions', group: GROUPS.MAP_BASED_DATA }),
  createOption({ value: 'road_attributes', group: GROUPS.MAP_BASED_DATA }),
  createOption({
    value: 'road_type',
    group: GROUPS.MAP_BASED_DATA,
    subgroup: SUBGROUPS.ENVIRONMENTAL_CONDITIONS,
  }),
  createOption({ value: 'lane_count', group: GROUPS.MAP_BASED_DATA }),
  createOption({ value: 'road_surface', group: GROUPS.MAP_BASED_DATA }),
]

const EXAMPLE_SAVED_QUERIES_OPTIONS = [
  'Bike lane incidents',
  'Hard braking in roundabouts',
]

const SAVED_QUERIES_OPTIONS: Option[] = [createOption({ value: '' })]

const SearchQueryBar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const pathname = location.pathname.split('/')
  const listRef = useRef<HTMLUListElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const tagRef = useRef<HTMLDivElement>(null)
  const contextRef = useRef<HTMLInputElement>(null)
  const [value] = useState(pathname[pathname.length - 1])
  const [search, setSearch] = useState<(Option | string)[]>([])
  const [tags, setTags] = useState<Record<MergedListKey, string>[]>([])
  const [showOperators, setShowOperators] = useState(false)
  const [showValues, setShowValues] = useState(false)
  const [disableCloseOnSelect, setDisableCloseOnSelect] = useState(true)
  const [clickedTagKey, setClickedTagKey] = useState<string | null>(null)
  const [editedTagKey, setEditedTagKey] = useState<string | null>(null)
  const [editedTag, setEditedTag] = useState<string | null>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const [context, setContext] = useState<string>('5s')
  const [showSaveQueryModal, setShowSaveQueryModal] = useState(false)
  const [autocompleteTab, setAutocompleteTab] = useState(AUTOCOMPLETE_TABS.NEW)
  const [savedQueries, setSavedQueries] = useState(SAVED_QUERIES_OPTIONS)
  const [savedQueryToDelete, setSavedQueryToDelete] = useState<string | null>(
    null
  )
  const [savedQueryToEdit, setSavedQueryToEdit] = useState<string | null>(null)
  const [clickedOutsideTagData, setClickedOutsideTagData] = useState()
  const [range, setRange] = useState<[Date, Date] | null>()

  useEffect(() => {
    if (search.length % 3 === 0) {
      setDisableCloseOnSelect(true)
    }
  }, [search])

  useEffect(() => {
    const savedQueries: Option[] = []
    // TODO: Replace with saved queries from YAPI
    EXAMPLE_SAVED_QUERIES_OPTIONS.forEach((value) => {
      savedQueries.push(
        createOption({
          value,
          onCopy: (value) => {
            // TODO: Add proper url
            navigator.clipboard.writeText('/')
          },
          onEdit: (value) => {
            setSavedQueryToEdit(value)
          },
          onDelete: (value) => {
            setSavedQueryToDelete(value)
          },
        })
      )
    })
    setSavedQueries(savedQueries)
  }, [EXAMPLE_SAVED_QUERIES_OPTIONS])

  useClickOutside(listRef, () => {
    setClickedTagKey(null)
    setEditedTagKey(null)
  })

  const updateTags = useCallback(() => {
    const updatedTags = [...tags]

    updatedTags.forEach((tag) => {
      if (tag.key === editedTagKey) {
        tag.value = editedTag || ''
      }
    })
    setTags(updatedTags)
    searchParams.set(
      'q',
      updatedTags.map((list) => encodeURIComponent(list.value)).join('&')
    )
    setSearchParams(searchParams)
    setEditedTag(null)
    setEditedTagKey(null)
  }, [tags, editedTag])

  useClickOutside(
    tagRef,
    (props) => {
      if (props.editedTagKey) {
        setClickedOutsideTagData(props)
      }
    },
    { editedTag, editedTagKey }
  )

  useEffect(() => {
    clickedOutsideTagData && updateTags()
  }, [clickedOutsideTagData])

  const onChange = useCallback(
    (event: React.SyntheticEvent, value: any, reason: string) => {
      const key = (event.target as HTMLElement)
        .closest('div')
        ?.getAttribute('data-key')
      if (reason === 'removeOption') {
        search.forEach((s, i) => {
          if ((s as Option).value === key) {
            value.splice(i, 3)
          }
        })
        setSearch([...value])
      } else {
        if (showValues) {
          setShowValues(false)
        }
        if ((value.length + 1) % 3 === 0) {
          setShowValues(true)
          setDisableCloseOnSelect(false)
          setShowOperators(false)
        } else if (value.length !== 3 && value.length % 3 !== 0) {
          setShowOperators(true)
        } else {
          setShowValues(false)
          setShowOperators(false)
        }
        setSearch(value)
      }
    },
    [search, showValues]
  )

  const renderTags = useCallback(
    (list: (Option | string)[], props: AutocompleteRenderGetTagProps) => {
      const displayList: string[] = list.map(
        (item) => (item as Option).name || (item as string)
      )

      const mergedList: Record<MergedListKey, string | string[]>[] = []

      for (let i = 0; i < displayList.length; i += 3) {
        mergedList.push({
          group: (search[0] as Option).group || '',
          subgroup: (search[0] as Option).subgroup || '',
          key: (list[i] as Option).value,
          value: [...displayList].splice(i, 3),
        })
      }

      return (
        <div className={style.searchTags}>
          {mergedList.map((merged, i) => {
            return (
              <div key={`${uuid.v4()}_${i}`} className={style.searchResults}>
                {merged.value.length % 3 === 0 ? (
                  <Chip
                    label={(merged.value as string[]).join(' ')}
                    variant="outlined"
                    {...props({ index: i })}
                    data-key={merged.key}
                    onDelete={(event) => {
                      onChange(event, list, 'removeOption')
                    }}
                  />
                ) : (
                  <div>{merged.value}</div>
                )}
              </div>
            )
          })}
        </div>
      )
    },
    [search]
  )

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter' && !showOperators && !showValues) {
        const mergedList: Record<MergedListKey, string>[] = tags
        const searchValues = []

        let value: string[] = []
        for (let i = 0; i <= search.length; i++) {
          if (i === 0 || i % 3 !== 0) {
            value.push((search[i] as Option)?.name || (search[i] as string))
          } else {
            mergedList.push({
              group: (search[0] as Option).group || '',
              subgroup: (search[0] as Option).subgroup || '',
              key: (search[i - 1] as Option).value || uuid.v4(),
              value: value.join(' '),
            })
            if (search[i]) {
              value = [(search[i] as Option).name]
            }
            searchValues.push(value.join(' '))
          }
        }

        searchParams.set(
          'q',
          mergedList.map((list) => encodeURIComponent(list.value)).join('&')
        )
        setSearchParams(searchParams)
        setTags(mergedList)
        setSearch([])
      }
    },
    [search, tags, showOperators, showValues]
  )

  useEffect(() => {
    const params = searchParams.getAll('q')[0]
    const splitParams = params ? params.split('&') : []
    const createdTags = splitParams.map((value) => {
      const type = decodeURIComponent(value).split(' ')[0].replace(/ /g, '')
      return {
        group: GROUPS_MAPPING[type as OPERATORS_KEYS] || '',
        subgroup: SUBGROUPS_MAPPING[type as SUBGROUPS_OPERATORS_KEYS] || '',
        value: decodeURIComponent(value),
        key: `${uuid.v4()}_${decodeURIComponent(value)}`,
      }
    })
    setTags(createdTags)
  }, [searchParams])

  useEffect(() => {
    const params = searchParams.getAll('context')[0]
    const newContext = params || context
    setContext(newContext)
    searchParams.set('context', newContext)
    setSearchParams(searchParams)
  }, [searchParams, context])

  useEffect(() => {
    const begin = searchParams.getAll('begin')[0]
    const end = searchParams.getAll('end')[0]
    if (begin && end) {
      setRange([new Date(begin), new Date(end)])
    } else {
      setRange(null)
    }
  }, [searchParams])

  const onContextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setContext(event.target.value)
      searchParams.set('context', event.target.value)
      setSearchParams(searchParams)
    },
    [searchParams]
  )

  const onContextKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Tab') {
        const newContext = context.indexOf('s') === -1 ? context + 's' : context
        setContext(newContext)
        searchParams.set('context', newContext)
      }
    },
    [context]
  )

  const onDatePickerChange = useCallback(
    (value: any) => {
      if (value?.[0] && value?.[1]) {
        searchParams.set('begin', `${endOfDay(value[0]).toISOString()}`)
        searchParams.set('end', `${endOfDay(value[1]).toISOString()}`)
      } else {
        searchParams.delete('begin')
        searchParams.delete('end')
        setRange(null)
      }
      setSearchParams(searchParams)
    },
    [searchParams]
  )

  return (
    <div>
      <div
        className={classNames(
          style.searchContainer,
          tags?.length === 0 ? style.searchContainerHeight : undefined
        )}
      >
        <div className={style.searchContext}>
          <Autocomplete
            id="search"
            multiple
            disableCloseOnSelect={disableCloseOnSelect}
            sx={{ width: '50%' }}
            componentsProps={{
              paper: {
                sx: {
                  maxWidth: '100%',
                },
              },
            }}
            freeSolo
            getOptionKey={(option: any) => (option as Option).value}
            onChange={onChange}
            className={style.autocomplete}
            clearOnBlur={false}
            renderTags={renderTags}
            value={search as any}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{ ...params.inputProps, autoComplete: 'off' }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                        <Icon
                          name={'Search'}
                          styles={{ fontSize: '1.25rem', color: '#7d8287' }}
                        />
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
                placeholder={search.length === 0 ? 'Enter query' : undefined}
              />
            )}
            disabled={false}
            options={
              autocompleteTab === AUTOCOMPLETE_TABS.NEW
                ? showValues && search.length > 0
                  ? VALUES[
                      (search[search.length - 2] as Option)?.name as VALUES_KEYS
                    ] || []
                  : showOperators && search.length > 0
                  ? OPERATORS[
                      (search[search.length - 1] as Option)
                        ?.name as OPERATORS_KEYS
                    ] || []
                  : OPTIONS
                : savedQueries
            }
            getOptionLabel={(option: any) => option.name}
            groupBy={(option: any) => option.group}
            renderGroup={(params) => {
              return (
                <div key={params.key}>
                  {!showOperators && parseInt(params.key) === 0 && (
                    <div className={style.newSavedQueries}>
                      <Typography
                        type={TypographyTypes.label}
                        className={classNames(
                          style.tab,
                          autocompleteTab === AUTOCOMPLETE_TABS.NEW
                            ? style.selectedTab
                            : undefined
                        )}
                        onClick={() =>
                          setAutocompleteTab(AUTOCOMPLETE_TABS.NEW)
                        }
                      >
                        {AUTOCOMPLETE_TABS.NEW}
                      </Typography>
                      <Typography
                        type={TypographyTypes.label}
                        className={classNames(
                          style.tab,
                          autocompleteTab === AUTOCOMPLETE_TABS.SAVED
                            ? style.selectedTab
                            : undefined
                        )}
                        onClick={() =>
                          setAutocompleteTab(AUTOCOMPLETE_TABS.SAVED)
                        }
                      >
                        {AUTOCOMPLETE_TABS.SAVED}
                      </Typography>
                    </div>
                  )}
                  <Typography
                    type={TypographyTypes.label}
                    className={style.group}
                  >
                    {params.group}
                  </Typography>
                  {params.children}
                </div>
              )
            }}
            renderOption={(props, option: any) => option.item(props)}
            forcePopupIcon={false}
            isOptionEqualToValue={() => false}
            onKeyDown={onKeyDown}
          />
          <div className={style.datePickerContainer}>
            <DateRangePicker
              character={'-'}
              size="lg"
              ranges={predefinedRanges}
              placement={'bottomEnd'}
              onChange={onDatePickerChange}
              value={range}
            />
          </div>
          <input
            className={style.contextInput}
            ref={contextRef}
            value={context}
            title={'Set context window'}
            onChange={onContextChange}
            onKeyDown={onContextKeyDown}
          />
          {tags.length > 0 && (
            <Button
              className={style.saveQuery}
              onClick={() => {
                setShowSaveQueryModal(true)
              }}
              text={'Save query'}
              secondary
            />
          )}
        </div>
        <Select
          className={style.subNavInputField}
          value={value}
          onChange={(e) => navigate(`/dataset/${e.target.value}`)}
          IconComponent={(_props) => {
            const opened = _props.className.toString().includes('iconOpen')
            return (
              <Icon
                className={style.iconSelect}
                name={opened ? 'ExpandLess' : 'ExpandMore'}
              />
            )
          }}
        >
          <MenuItem value="drives">
            <p>Drives</p>
          </MenuItem>
          <MenuItem value="scenarios">
            <p>Scenarios</p>
          </MenuItem>
        </Select>
      </div>
      {tags && tags.length > 0 && (
        <div className={style.tagsContainer}>
          <div className={style.tags}>
            {tags.map((tag) => {
              return (
                <div key={tag.key} className={style.tagContainer} ref={tagRef}>
                  <Chip
                    className={style.chip}
                    label={
                      editedTagKey === tag.key ? (
                        <>
                          <input
                            ref={inputRef}
                            className={style.editTagInput}
                            autoFocus={true}
                            value={editedTag || ''}
                            onChange={(event) =>
                              setEditedTag(event.target.value)
                            }
                            onKeyDown={(
                              event: React.KeyboardEvent<HTMLInputElement>
                            ) => {
                              if (event.key === 'Enter') {
                                updateTags()
                              }
                            }}
                          />
                          <Icon
                            className={style.icon}
                            name={'Check'}
                            onClick={() => {
                              updateTags()
                            }}
                          />
                          <Icon
                            className={style.icon}
                            name={'Close'}
                            onClick={() => {
                              setEditedTagKey(null)
                            }}
                          />
                        </>
                      ) : (
                        tag.value
                      )
                    }
                    variant="outlined"
                    key={tag.key}
                    deleteIcon={<Icon name={'MoreHorizontal'} />}
                    onDelete={
                      editedTagKey === tag.key
                        ? undefined
                        : () => {
                            setClickedTagKey(tag.key)
                          }
                    }
                  />
                  {tag.key === clickedTagKey && (
                    <List
                      aria-label="tag actions"
                      ref={listRef}
                      className={style.editTagList}
                    >
                      <ListItem
                        onClick={() => {
                          setEditedTagKey(tag.key)
                          setEditedTag(tag.value)
                          setClickedTagKey(null)
                        }}
                      >
                        <ListItemText primary="Edit" />
                      </ListItem>
                      <ListItem
                        onClick={() => {
                          const updatedTags = [...tags].filter(
                            (tag) => tag.key !== clickedTagKey
                          )
                          setTags(updatedTags)
                          searchParams.set(
                            'q',
                            updatedTags
                              .map((list) => encodeURIComponent(list.value))
                              .join('&')
                          )
                          setSearchParams(searchParams)
                          setClickedTagKey(null)
                          setEditedTag(null)
                        }}
                      >
                        <ListItemText primary="Delete" />
                      </ListItem>
                    </List>
                  )}
                </div>
              )
            })}
          </div>
          {tags.length > 0 && (
            <Button
              className={style.clearAll}
              onClick={() => {
                setTags([])
                searchParams.delete('q')
                setSearchParams(searchParams)
              }}
              text={'Clear all'}
              secondary
            />
          )}
        </div>
      )}
      <SaveEditQueryDialog
        edit={savedQueryToEdit}
        open={showSaveQueryModal || savedQueryToEdit !== null}
        // TODO: When editing get tags from saved query
        tags={[...tags]}
        onCancel={() => {
          setShowSaveQueryModal(false)
          setSavedQueryToEdit(null)
        }}
        onConfirmed={(tags) => {
          setTags(tags)
          setShowSaveQueryModal(false)
          // TODO: API call to save queries endpoint
          return new Promise(() => {})
        }}
      />
      <WarningDialog
        isOpen={savedQueryToDelete !== null}
        dialogContentText={`Delete "${savedQueryToDelete}"?`}
        dialogContentText2={`This action cannot be undone.`}
        dialogTitle={''}
        onSubmit={() => {
          // TODO: Implement me
        }}
        onCancel={() => {
          setSavedQueryToDelete(null)
        }}
        buttons={{
          cancel: 'Cancel',
          submit: 'Delete',
        }}
      />
    </div>
  )
}

export default SearchQueryBar
