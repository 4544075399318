import React, { useCallback, useEffect, useState } from 'react'
import FormDialog, {
  FormDialogTypes,
  FormTextInputProps,
} from '@yaak/components/src/FormDialog/FormDialog'
import { MergedListKey } from '../SearchQueryBar'

type GROUP_KEYS = 'GENERAL' | 'DYNAMIC DATA' | 'MAP-BASED DATA'

const SORT_ORDER = ['GENERAL', 'DYNAMIC DATA', 'MAP-BASED DATA']

interface SaveQueryDialogProps {
  edit: string | null
  open: boolean
  onCancel: () => void
  onConfirmed: (result: any) => Promise<any>
  tags: Record<MergedListKey, string>[]
}

interface buildSaveQueryDialogInputsProps {
  edit: string | null
  inputs: Record<MergedListKey, string>[]
  deleteCallback: (input: any) => void
  onChange: (input: FormTextInputProps, value: string) => void
}

const buildSaveQueryDialogInputs = async ({
  edit,
  inputs,
  deleteCallback,
  onChange,
}: buildSaveQueryDialogInputsProps): Promise<FormTextInputProps[]> => {
  const dialogInputs: FormTextInputProps[] = [
    {
      name: 'name',
      title: 'Query name',
      placeHolderText: 'Enter query name',
      value: edit,
    },
  ]

  const groupedInputs = inputs.reduce(
    (acc: Record<GROUP_KEYS, Record<MergedListKey, string>[]>, input) => {
      if (acc[input.group as GROUP_KEYS]) {
        acc[input.group as GROUP_KEYS].push(input)
      } else {
        acc[input.group as GROUP_KEYS] = [input]
      }
      return acc
    },
    {
      GENERAL: [],
      'DYNAMIC DATA': [],
      'MAP-BASED DATA': [],
    }
  )

  const ordering: Record<GROUP_KEYS, number> = {
    GENERAL: 0,
    'DYNAMIC DATA': 0,
    'MAP-BASED DATA': 0,
  }
  for (let i = 0; i < SORT_ORDER.length; i++) {
    ordering[SORT_ORDER[i] as GROUP_KEYS] = i
  }

  const orderedInputs = Object.keys(groupedInputs)
    .sort(
      (groupedInput1, groupedInput2) =>
        ordering[groupedInput1 as GROUP_KEYS] -
        ordering[groupedInput2 as GROUP_KEYS]
    )
    .reduce(
      (acc: Record<GROUP_KEYS, Record<MergedListKey, string>[]>, key) => {
        acc[key as GROUP_KEYS] = groupedInputs[key as GROUP_KEYS].sort(
          (input1, input2) => input1.subgroup.localeCompare(input2.subgroup)
        )
        return acc
      },
      {
        GENERAL: [],
        'DYNAMIC DATA': [],
        'MAP-BASED DATA': [],
      }
    )

  Object.keys(orderedInputs).forEach((key) => {
    orderedInputs[key as GROUP_KEYS].forEach((input, i) => {
      dialogInputs.push({
        key: input.key,
        name: input.value,
        subtitle: i === 0 ? input.group : '',
        title: input.subgroup || '',
        value: input.value,
        deleteCallback,
        onChange,
      })
    })
  })

  return dialogInputs
}
const SaveEditQueryDialog: React.FunctionComponent<SaveQueryDialogProps> = ({
  edit,
  open,
  onCancel,
  onConfirmed,
  tags,
}) => {
  const [inputs, setInputs] = useState<Record<MergedListKey, string>[]>()

  useEffect(() => {
    setInputs(tags)
  }, [tags])

  const buildInputs = useCallback(() => {
    const buildInputs = buildSaveQueryDialogInputs({
      edit,
      inputs: inputs || [],
      deleteCallback: (input) =>
        setInputs((prev) => prev?.filter((i) => i.key !== input.key)),
      onChange: (input, value) => {
        setInputs((prev) =>
          prev?.map((i) => {
            if (i.key === input.key) {
              i.value = value
            }
            return i
          })
        )
      },
    })
    return buildInputs
  }, [inputs, edit])

  return (
    <FormDialog
      title={edit ? 'Edit query' : 'Save query'}
      dialogType={FormDialogTypes.edit}
      inputs={buildInputs}
      open={open}
      confirmationTitle={''}
      onCancel={onCancel}
      onConfirmed={() => onConfirmed(inputs)}
    ></FormDialog>
  )
}

export default SaveEditQueryDialog
