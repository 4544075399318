import React, { useState, useEffect } from 'react'
import style from './style.less'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Autocomplete from '@mui/material/Autocomplete'
import Typography, {
  TypographyTypes,
  TypographySizes,
} from '../Typography/Typography'
import Button from '../Button/Button'
import Switch from '../Switch'
import Icon from '../Icon'
import classNames from 'classnames'

export const FormDialogTypes = {
  edit: 'edit',
  creating: 'creating',
}

export interface SelectionProp {
  value: any
  name: string
  item: React.ReactNode
}

export interface FormInputProps {
  name: string
  title: string
  description?: string
  placeHolderText?: string
  required?: boolean
  disabled?: boolean
  subtitle?: string
}

export interface FormBooleanInputProps extends FormInputProps {
  value?: boolean
}

export interface FormTextInputProps extends FormInputProps {
  key?: string
  value?: string | null
  validate?: (value: string) => boolean
  errorText?: string
  deleteCallback?: (input: any) => void
  onChange?: (input: FormTextInputProps, value: string) => void
}

export interface FormListInputProps extends FormInputProps {
  value?: string | null
  selections: Array<SelectionProp>
  editable?: boolean
}

export type InputProp =
  | FormTextInputProps
  | FormListInputProps
  | FormBooleanInputProps

export interface FormDetailsEntry {
  icon: React.ReactNode
  value: string
}

export interface FormDialogProps {
  title: string
  titleIcon?: React.ReactNode
  open: boolean
  dialogType?: string
  inputs: () => Promise<Array<InputProp>>
  confirmationTitle: string
  toDetailEntries?: (result: any) => Array<FormDetailsEntry>
  onCancel: () => void
  onConfirmed: (result: any) => Promise<any>
  onDelete?: () => void
}

const FormDialog: React.FunctionComponent<FormDialogProps> = ({
  title,
  titleIcon,
  open,
  inputs,
  confirmationTitle,
  dialogType = FormDialogTypes.creating,
  toDetailEntries,
  onCancel,
  onConfirmed,
  onDelete,
}) => {
  const [resolvedInputs, setResolvedInputs] = useState<Array<InputProp>>([])
  const [result, setResult] = useState<any>({})
  const [ready, setReady] = useState<boolean>(false)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [defaultValues, setDefaultValues] = useState<any>({})
  const [requiredFields, setRequiredFields] = useState<Array<string>>([])

  useEffect(() => {
    setRequiredFields(
      resolvedInputs
        .filter((input) => input.required)
        .map((input) => input.name)
    )
    setResult(
      resolvedInputs.reduce(
        (pre, curr) =>
          curr.value ? Object.assign(pre, { [curr.name]: curr.value }) : pre,
        {}
      )
    )
    setDefaultValues(
      resolvedInputs.reduce(
        (pre, curr) =>
          curr.value ? Object.assign(pre, { [curr.name]: curr.value }) : pre,
        {}
      )
    )
  }, [resolvedInputs])

  useEffect(() => {
    !open && resetDialog()
    open &&
      inputs()
        .then((resolved) => {
          setResolvedInputs(resolved)
        })
        .catch(() => onCancel())
  }, [open])

  const isReadyToSubmit = () =>
    requiredFields.every((field) => result[field] !== undefined) &&
    resolvedInputs
      .filter(
        (i): i is FormTextInputProps =>
          (i as FormTextInputProps).validate !== undefined
      )
      .every(({ name, validate }) =>
        result[name] !== undefined || result[name] !== null
          ? validate!(result[name])
          : true
      )

  const onFieldChanged = (fieldName: string, value: any) => {
    result[fieldName] = value
    setResult(result)
    setReady(isReadyToSubmit())
  }

  const onDialogConfirm = () => {
    onConfirmed(result)
  }

  const onDialogCancel = () => {
    onCancel()
  }

  const resetDialog = () => {
    setResult({})
    setDefaultValues({})
    setReady(false)
    setSubmitted(false)
  }

  const editDialog = (result: any) => {
    return (
      <>
        <Typography type={TypographyTypes.title} size={TypographySizes.large}>
          {title}
        </Typography>
        <Typography type={TypographyTypes.label}>
          <div className={style.inputBox}>
            {resolvedInputs.map(toInputField(result))}
          </div>
        </Typography>
      </>
    )
  }

  const toInputField = (result: any) => {
    const input = (input: InputProp, idx: number) => {
      let inputField
      if (typeof input.value === 'boolean') {
        inputField = (
          <FormBooleanInput
            props={input as FormBooleanInputProps}
            onChange={onFieldChanged}
            defaultValue={input.value}
          />
        )
      } else {
        const defaultValue =
          result[input.name] || defaultValues[input.name] || ''
        inputField = (
          <div className={style.inputFieldBox}>
            {input.subtitle ? <div>{input.subtitle}</div> : ''}
            {`${input.title}${input.required ? '*' : ''}`}
            {'selections' in input ? (
              <FormListInput
                props={input as FormListInputProps}
                defaultValue={defaultValue}
                onChange={onFieldChanged}
              />
            ) : (
              <FormTextInput
                props={input as FormTextInputProps}
                onChange={(fieldName: string, value: any) => {
                  ;(input as FormTextInputProps).onChange?.(
                    input as FormTextInputProps,
                    value
                  )
                  onFieldChanged(fieldName, value)
                }}
                onDelete={(field: any) => {
                  delete result[field.value]
                  const newResolved = [...resolvedInputs].filter(
                    (input) => (input as FormTextInputProps).key !== field.key
                  )
                  newResolved.forEach((nR) => {
                    nR.value = result[nR.name]
                  })
                  setResult(result)
                  setResolvedInputs(newResolved)
                }}
                defaultValue={defaultValue}
              />
            )}
          </div>
        )
      }

      return (
        <div key={`${idx}_${Object.keys(defaultValues).length}`}>
          {' '}
          {inputField}
        </div>
      )
    }
    return input
  }

  const confirmationDialog = () => {
    return (
      <>
        <Typography type={TypographyTypes.title} size={TypographySizes.large}>
          {confirmationTitle}
        </Typography>
        <Typography type={TypographyTypes.body}>
          <div className={style.detailsBox}>
            {toDetailEntries?.(result).map(toDetailsItem)}
          </div>
        </Typography>
      </>
    )
  }

  const toDetailsItem = (input: FormDetailsEntry, idx: number) => (
    <div className={style.detailsItem} key={idx}>
      {input.icon}
      {input.value}
    </div>
  )

  const submitButton = (
    <Button
      onClick={() => setSubmitted(true)}
      text={'Submit'}
      disabled={!ready}
    />
  )

  const saveChangesButton = (
    <Button onClick={onDialogConfirm} text={'Save changes'} disabled={!ready} />
  )

  return (
    <Dialog open={open} PaperProps={{ sx: { borderRadius: '0.75rem' } }}>
      <DialogContent className={style.dialog}>
        {titleIcon && <div className={style.titleIcon}>{titleIcon}</div>}
        {submitted && dialogType === FormDialogTypes.creating
          ? confirmationDialog()
          : editDialog(result)}
        <div className={style.dialogActions}>
          <div className={style.dialogDefaultActionBox}>
            <Button onClick={onDialogCancel} text={'Cancel'} secondary />
            {submitted ? (
              <Button onClick={onDialogConfirm} text={title} />
            ) : dialogType === FormDialogTypes.creating ? (
              submitButton
            ) : (
              saveChangesButton
            )}
          </div>
          {submitted ? (
            <Button
              onClick={() => setSubmitted(false)}
              text={'Edit details'}
              tertiary
            />
          ) : undefined}
          {onDelete && (
            <Button
              onClick={onDelete}
              text={'Delete'}
              tertiary
              className={style.deleteButton}
            />
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}

const FormTextInput: React.FunctionComponent<{
  props: FormTextInputProps
  defaultValue: string
  onChange: (fieldName: string, value: any) => void
  onDelete: (props: FormTextInputProps, value: any) => void
}> = ({ props, defaultValue, onChange, onDelete }) => {
  const [hasError, setHasError] = useState<boolean>(false)

  const onInputChange = (event: any) => {
    setHasError(props.validate?.(event.target.value) === false)
    onChange(props.name, event.target.value)
  }

  return (
    <div className={style.inputContainer}>
      <TextField
        placeholder={props.placeHolderText}
        className={style.inputField}
        defaultValue={defaultValue}
        onChange={onInputChange}
        inputProps={{ style: { padding: '0.875rem 1rem' } }}
        InputProps={{ style: { borderRadius: '0.5rem' } }}
        error={hasError}
        helperText={hasError ? props.errorText : props.description}
        disabled={props.disabled === true}
      />
      {props.deleteCallback && (
        <div onClick={onInputChange}>
          <Icon
            name={'Delete'}
            className={style.icon}
            onClick={(event) => {
              event.stopPropagation()
              onDelete(props, event.target.value)
              props.deleteCallback?.(props)
            }}
          />
        </div>
      )}
    </div>
  )
}

const FormListInput: React.FunctionComponent<{
  props: FormListInputProps
  defaultValue: string
  onChange: (fieldName: string, value: any) => void
}> = ({ props, defaultValue, onChange }) => {
  return props.editable ? (
    <Autocomplete
      classes={{ paper: style.autocompleteFieldMenu }}
      renderInput={(params) => (
        <TextField {...params} placeholder={props.placeHolderText} />
      )}
      defaultValue={defaultValue || null}
      onChange={(event) => {
        onChange(props.name, (event.target as HTMLElement).textContent)
      }}
      disabled={props.disabled === true}
      options={props.selections as any}
      getOptionLabel={(option: any) => option.value}
      renderOption={(props, option: any) => option.item(props)}
      popupIcon={<Icon name={'ExpandMore'} />}
    />
  ) : (
    <Select
      displayEmpty
      className={style.inputField}
      defaultValue={defaultValue}
      onChange={(event) => onChange(props.name, event.target.value)}
      disabled={props.disabled === true}
      MenuProps={{ classes: { paper: style.inputFieldMenu } }}
      IconComponent={(_props) => {
        const opened = _props.className.toString().includes('iconOpen')
        return (
          <Icon
            className={style.iconSelect}
            name={opened ? 'ExpandLess' : 'ExpandMore'}
          />
        )
      }}
      renderValue={(value) => {
        return !value
          ? props.placeHolderText
          : props.selections.map(
              (selection) => selection.value === value && selection.item
            )
      }}
    >
      {props.selections.map((selection, index) => (
        <MenuItem key={index} value={selection.value}>
          {selection.item}
        </MenuItem>
      ))}
    </Select>
  )
}

const FormBooleanInput: React.FunctionComponent<{
  props: FormBooleanInputProps
  defaultValue: boolean
  onChange: (fieldName: string, value: any) => void
}> = ({ props, defaultValue, onChange }) => {
  const [state, setState] = useState<boolean>(true)

  useEffect(() => setState(defaultValue), [])

  const onStateChange = (event: any) => {
    event.stopPropagation()
    setState(!state)
    onChange(props.name, !state)
  }

  return (
    <div
      className={classNames(
        style.booleanInput,
        props.disabled ? style.booleanInputDisabled : undefined
      )}
    >
      <Typography type={TypographyTypes.body}>{props.title}</Typography>
      <div className={style.toggle}>
        <Switch
          checked={state}
          onClick={(event) => event.stopPropagation()}
          onChange={onStateChange}
          disabled={props.disabled}
        />
      </div>
    </div>
  )
}

export default FormDialog
